import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';
import SigninLayout from "../../layouts/signinLayout";

const LoginContainer = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await fetch('https://findbluapp.com/web/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_LOGIN_KEY
                },
                body: JSON.stringify({ email: email, password: password })
            });
    
            const responseData = await response.json(); // Response'ı sadece bir kez okuyun
    
            if (response.ok) {
                const { status } = responseData; // responseData üzerinden status değerini alın
                if (status === 'success') {
                    dispatch({ type: 'LOGIN', payload: { email } }); 
                    navigate('/', { replace: true });
                } else {
                    setErrorMessage('Invalid credentials');
                }
            } else {
                setErrorMessage(responseData.message); // responseData üzerinden hata mesajını alın
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred. Please try again later.');
        }
    };
    

    useEffect(() => {
        if (isLoggedIn === true) {
            navigate('/', { replace: true });
        }
    }, [isLoggedIn, navigate])

    return (
        <SigninLayout>
            <div>
                <div className={styles.loginCard}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="emailInput">Email</label>
                            <input
                                type="email"
                                id="emailInput"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="passwordInput">Password</label>
                            <input
                                id="passwordInput"
                                type="password"
                                placeholder="******"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>} {/* Hata mesajını görüntüleyin */}
                        <button type="submit" className={styles.submitBtn}>Login</button>
                    </form>
                </div>
            </div>
        </SigninLayout>
    );
};

export default LoginContainer;
