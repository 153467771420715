import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Card } from 'antd';
import MainLayout from '../../layouts/mainLayout';
import DateFilterButtons from '../../components/datefilterbuttons';
import GroupedDataView from '../../components/groupdataview';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    ArcElement
);

const EventsSubscribe = () => {
    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== '');
    const appid = segments[1];

    const [successData, setSuccessData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sorter, setSorter] = useState({});
    const [formattedCountries, setFormattedCountries] = useState({});
    const [recordIds] = useState({});
    const [filteredData, setFilteredData] = useState(successData);

    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
    };

    useEffect(() => {
        const fetchData = async (appId) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://findbluapp.com/web/eventsubscribe', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'get',
                        appId: appId
                    })
                });
                const responseData = await response.json();
                const newDataWithIds = responseData.data.successfulAttemps.map((record, index) => {
                    if (!record.id) {
                        const newId = index;
                        record.id = newId;
                        recordIds[newId] = record;
                    }
                    return record;
                });
                setSuccessData(newDataWithIds);
                setFilteredData(newDataWithIds);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData(appid);
    }, [appid]);

    useEffect(() => {
        handleDateFilterChange({ days: 0 });
        const fetchAllCountries = async () => {
            const uniqueCountryCodes = new Set(successData.map(item => item.subscribe.country));

            try {
                const response = await fetch('https://findbluapp.com/web/countries', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        countryCodes: Array.from(uniqueCountryCodes)
                    })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                setFormattedCountries(responseData.data);
            } catch (error) {
                console.error('Error fetching formatted countries:', error);
            }
        };

        fetchAllCountries();
    }, [successData]);

    const handleDateFilterChange = (range) => {
        if (range === null) {
            setFilteredData(successData);
            return;
        }
        if (range.days === 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const filtered = successData.filter((item) => {
                const entryDate = new Date(item.subscribe.entryDate);
                return entryDate >= today;
            });
            setFilteredData(filtered);
            return;
        }

        if (range.days || range.months) {
            const today = new Date();
            let startDate = new Date();

            if (range.days) {
                startDate.setDate(today.getDate() - range.days);
            } else if (range.months) {
                startDate.setMonth(today.getMonth() - range.months);
            }

            const filtered = successData.filter((item) => {
                const entryDate = new Date(item.subscribe.entryDate);
                return entryDate >= startDate;
            });
            setFilteredData(filtered);
        } else {
            const filtered = successData.filter((item) => {
                const entryDate = new Date(item.subscribe.entryDate);
                return (entryDate >= range.startDate && entryDate <= range.endDate);
            });
            setFilteredData(filtered);
        }
    };

    const groupedData = {
        action: {},
        placement: {},
        country: {}
    };

    filteredData.forEach((item) => {
        const actionName = item.subscribe.actionName;
        const placementId = item.subscribe.placementId;

        if (!groupedData.action[actionName]) {
            groupedData.action[actionName] = 0;
        }
        groupedData.action[actionName]++;

        if (!groupedData.placement[placementId]) {
            groupedData.placement[placementId] = 0;
        }
        groupedData.placement[placementId]++;

        const countryCode = item.subscribe.country;
        const countryName = formattedCountries[countryCode]?.name || countryCode;
        const countryIconUrl = formattedCountries[countryCode]?.iconUrl || '';

        if (!groupedData.country[countryName]) {
            groupedData.country[countryName] = {
                name: countryName,
                iconUrl: countryIconUrl,
                count: 0
            };
        }
        groupedData.country[countryName].count++;
    });

    const sortedCountryEntries = Object.entries(groupedData.country).sort(([, a], [, b]) => b.count - a.count);
    groupedData.country = Object.fromEntries(sortedCountryEntries);
    const sortedPlacementEntries = Object.entries(groupedData.placement).sort(([, a], [, b]) => b - a);
    groupedData.placement = Object.fromEntries(sortedPlacementEntries);
    const sortedActionEntries = Object.entries(groupedData.action).sort(([, a], [, b]) => b - a);
    groupedData.action = Object.fromEntries(sortedActionEntries);

    const chartData = {
        labels: Object.keys(groupedData.country),
        datasets: [
            {
                label: 'Country Count',
                data: Object.values(groupedData.country).map(item => item.count),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            }
        ]
    };

    const pieData = {
        labels: Object.keys(groupedData.action), // Etiketler
        datasets: [{
            label: 'Action Count',
            data: Object.values(groupedData.action), // Veriler
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1,
        }]
    };


    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y;
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                reverse: false,
            },
            y: {
                beginAtZero: true,
                reverse: false
            }
        },
        indexAxis: 'y'
    };

    const pieOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: true, // Legend'ı göster
                position: 'top', // Legend'ın konumu
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        let value = context.raw || 0;
                        if (label) {
                            label += ': ';
                        }
                        if (value) {
                            label += value;
                        }
                        return label;
                    }
                }
            }
        }
    };



    const columns = [
        {
            title: 'Action Name',
            dataIndex: ['subscribe', 'actionName'],
            key: 'actionName',
        },
        {
            title: 'Product Code',
            dataIndex: ['subscribe', 'productCode'],
            key: 'productCode',
        },
        {
            title: 'Placement ID',
            dataIndex: ['subscribe', 'placementId'],
            key: 'placementId',
        },
        {
            title: 'Country',
            dataIndex: ['subscribe', 'country'],
            key: 'country',
            render: (text, record) => {
                const countryCode = record.subscribe.country;
                const formattedCountry = formattedCountries[countryCode];
                if (formattedCountry) {
                    return (
                        <div className='flex items-center justify-start gap-2'>
                            <img width="20" className='h-4' src={formattedCountry.iconUrl} alt={formattedCountry.name} loading='lazy' />
                            <span>{formattedCountry.name}</span>
                        </div>
                    );
                } else {
                    return <div>Loading country data...</div>;
                }
            },
        },
        {
            title: 'Date',
            dataIndex: ['subscribe', 'entryDate'],
            key: 'entryDate',
            sorter: (a, b) => new Date(a.subscribe.entryDate) - new Date(b.subscribe.entryDate),
        },
    ];

    return (
        <MainLayout>
            <div className='flex justify-between'>
                <DateFilterButtons onDateFilterChange={handleDateFilterChange} />
                <div className='p-2 px-4 bg-white rounded'>
                    <p className='m-0 p-0'>Total subscribe: <b>{filteredData.length}</b></p>
                </div>
            </div>

            <div className='flex gap-4'>
                <div className='w-3/5'>
                    <Card title="Country Distribution" className='h-full'>
                        <div className='relative h-64'>
                            <Bar
                                data={chartData}
                                options={chartOptions}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </div>
                    </Card>
                </div>
                <div className='w-2/5 flex flex-col'>
                    <Card title="Action Distribution" className='flex-grow'>
                        <div className='relative h-64'>
                            <Pie
                                data={pieData}
                                options={pieOptions}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </div>
                    </Card>
                </div>
            </div>

            <div className=''>
                <GroupedDataView title="Action" data={groupedData.action} />
                <GroupedDataView title="Placement" data={groupedData.placement} />
                <GroupedDataView title="Country" data={groupedData.country} />
            </div>
            <div>
                {isLoading ? (
                    <h1>Loading...</h1>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={filteredData}
                        pagination={{ pageSize: 8 }}
                        loading={isLoading}
                        rowKey={(record) => record.id}
                        onChange={handleTableChange}
                        sorter={sorter}
                    />
                )}
            </div>
        </MainLayout>
    );
};

export default EventsSubscribe;
