import React from "react";

const MainLayout = ({ children }) => {
    return (
        <div className="App signin">
            <main>
                {children}
            </main>
        </div>
    );
};

export default MainLayout;
