import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Input, Checkbox, Button } from 'antd';
import MainLayout from '../../layouts/mainLayout';

const Demodevice = () => {
    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== ''); // URL'yi '/' karakterlerine göre ayırıp boş olmayanları alıyoruz
    const appid = segments[1];
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        deviceId: '',
        status: false,
        appId: appid
    });
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://findbluapp.com/web/demodevice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'get',
                    appId: appid
                })
            });
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://findbluapp.com/web/demodevice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'insert',
                    formData: formData
                })
            });

            const responseData = await response.json();
            console.log(responseData);
            if (response.ok) {
                fetchData();
                setFormData({
                    name: '',
                    deviceId: '',
                    status: false,
                    appId: appid
                });
            } else {
                console.error('Response is not OK');
            }
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };

    // const handleDelete = async (deviceId) => {
    //     try {
    //         const response = await fetch('https://findbluapp.com/web/demodevice', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 action: 'delete',
    //                 deviceId: deviceId
    //             }),
    //         });

    //         if (response.ok) {
    //             fetchData();
    //         } else {
    //             console.error('Response is not OK');
    //         }
    //     } catch (error) {
    //         console.error('Error deleting device:', error);
    //     }
    // };

    const handleEdit = (index) => {
        const newData = [...data];
        newData[index].editMode = true;
        setData(newData);
    };

    const handleSave = async (index) => {
        const updatedDevice = data[index];

        try {
            const response = await fetch('https://findbluapp.com/web/demodevice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'update',
                    updatedDevice: {
                        device_id: updatedDevice.device_id,
                        device_name: updatedDevice.device_name,
                        status: updatedDevice.status
                    }
                }),
            });

            if (response.ok) {
                fetchData();
            } else {
                console.error('Response is not OK');
            }

            const newData = [...data];
            newData[index].editMode = false;
            setData(newData);
        } catch (error) {
            console.error('Error updating device:', error);
        }
    };

    const handleChange = (e, index) => {
        const { name, value, type, checked } = e.target;
        const newData = [...data];
        newData[index][name] = type === 'checkbox' ? checked : value;
        setData(newData);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'device_name',
            key: 'device_name',
        },
        {
            title: 'Device Id',
            dataIndex: 'device_id',
            key: 'device_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record, index) => {
                if (record.editMode) {
                    return (
                        <Checkbox
                            name="status"
                            checked={status}
                            onChange={(e) => handleChange(e, index)}
                        />
                    );
                } else {
                    return status ? 'Active' : 'Inactive';
                }
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record, index) => {
                if (record.editMode) {
                    return (
                        <Button onClick={() => handleSave(index)}>Save</Button>
                    );
                } else {
                    return (
                        <>
                            <Button onClick={() => handleEdit(index)}>Edit</Button>
                            {/* <Button onClick={() => handleDelete(record.device_id)}>Delete</Button> */}
                        </>
                    );
                }
            },
        },
    ];


    return (
        <MainLayout>
            <div>
                <form onSubmit={handleSubmit} className='flex gap-4 mb-4'>
                    <Input
                        type="text"
                        placeholder='name'
                        name="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        className='p-2 text-black rounded'
                    />
                    <Input
                        type="text"
                        placeholder='device id'
                        name="deviceId"
                        value={formData.deviceId}
                        onChange={(e) => setFormData({ ...formData, deviceId: e.target.value })}
                        className='p-2 text-black rounded'
                    />
                    <Checkbox
                        type="checkbox"
                        name="status"
                        checked={formData.status}
                        onChange={(e) => setFormData({ ...formData, status: e.target.checked })}
                    />
                    <button className='bg-slate-500 py-2 px-6 rounded font-bold' type="submit">Add</button>
                </form>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={data}
                        loading={isLoading}
                        rowKey={record => record.device_id}
                    />

                )}
            </div>
        </MainLayout>
    );
};

export default Demodevice;
