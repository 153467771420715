// store.js

import { createStore } from 'redux';

// State'i tanımlayın
const initialState = {
    auth: {
        isLoggedIn: localStorage.getItem('isLoggedIn') || false,
    },
    email: '',
};

// Reducer fonksiyonu
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem('isLoggedIn', true);
            return {
                ...state,
                auth: { ...state.auth, isLoggedIn: true },
                email: action.payload.email // Email bilgisini action'dan alıp state'e ekleyin
            };
        case 'LOGOUT':
            localStorage.setItem('isLoggedIn', false);
            return {
                ...state,
                auth: { ...state.auth, isLoggedIn: false },
                email: '' // Logout yaptığında email state'ini sıfırlayın
            };
        case 'SET_EMAIL': // Email'i direkt değiştirmek için bir action ekleyin
            return { ...state, email: action.payload.email };
        default:
            return state;
    }
};



// Store'u oluşturun
const store = createStore(reducer);

export default store;
