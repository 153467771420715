import { BiArrowFromRight, BiArrowFromLeft, BiChevronUp, BiChevronDown, BiSubdirectoryRight } from "react-icons/bi";
import { useContext, createContext, useState } from "react";
import { Link } from "react-router-dom"; // Link bileşenini ekledik
import { useSelector } from 'react-redux';

const SidebarContext = createContext();

export default function Sidebar({ children, userName, userEmail }) {
    const email = useSelector(state => state.email);
    const [expanded, setExpanded] = useState(false);
    // const logo2url = process.env.LOGO2URL;

    return (
        <aside className="h-screen">
            <nav className="h-full flex flex-col bg-white border-r shadow-sm">
                <div className="p-4 pb-2 flex justify-between items-center">
                    <img
                        src="https://img.logoipsum.com/243.svg"
                        className={`overflow-hidden transition-all ${expanded ? "w-32" : "w-0"
                            }`}
                        alt=""
                    />
                    <button
                        onClick={() => setExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                    >
                        {expanded ? <BiArrowFromRight /> : <BiArrowFromLeft />}
                    </button>
                </div>

                <SidebarContext.Provider value={{ expanded }}>
                    <ul className="flex-1 px-3">{children}</ul>
                </SidebarContext.Provider>

                <div className="border-t flex p-3">
                    <img
                        src="https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&bold=true"
                        alt=""
                        className="w-10 h-10 rounded-md"
                    />
                    <div
                        className={`
                flex justify-between items-center
                overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}
            `}
                    >
                        <div className="leading-4">
                            <h4 className="font-semibold">Kerem Unce</h4>
                            <span className="text-xs text-gray-600">{email}</span>
                        </div>
                    </div>
                </div>
            </nav>
        </aside>
    );
}

export function SidebarItem({ icon, text, active, alert, route, children }) {
    const { expanded } = useContext(SidebarContext);
    const [open, setOpen] = useState(false);

    return (
        <li>
            {children ? (
                <>
                    <div
                        onClick={() => children && setOpen(!open)} // Children varsa tık işlemini değiştir
                        className={`
                    relative flex justify-start items-center py-2 px-3 my-1
                    font-medium rounded-md cursor-pointer
                    transition-all group ease-in
                    ${open ? "bg-gradient-to-tr from-green-200 to-green-100 text-green-800" : "hover:bg-green-50 text-gray-600"}
                `}
                    >
                        {icon}
                        <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{text}</span>
                        {/* ... diğer kodlar (alert, tooltip vb.) */}

                        {children && (
                            <span className="ml-auto">
                                {/* Submenu açma/kapama butonu */}
                                {open ? <BiChevronUp /> : <BiChevronDown />}
                            </span>
                        )}
                    </div>

                    {/* Submenu İşlemleri */}
                    {children && open && (
                        <ul className={` ml-${expanded ? '12' : '6'} ${open ? 'opacity-100 bg-gradient-to-tr from-green-200 to-green-100 text-green-800' : 'opacity-0 hover:bg-green-500 text-gray-600'}`}>
                            {children}
                        </ul>
                    )}
                </>
            ) : (
                <Link to={route} className={`
                relative flex items-center py-2 px-3 my-1
                font-medium rounded-md cursor-pointer
                transition-colors group
                ${active ? "bg-gradient-to-tr from-green-200 to-green-100 text-green-800" : "hover:bg-green-50 text-gray-600"}
            `}>
                    {icon}
                    <span className={`overflow-hidden transition-all ${expanded ? "w-52 ml-3" : "w-0"}`}>{text}</span>
                    {alert && (
                        <div className={`absolute right-2 w-2 h-2 rounded bg-green-400 ${expanded ? "" : "top-2"}`} />
                    )}

                    {!expanded && (
                        <div className={`
                        absolute left-full rounded-md px-2 py-1 ml-6
                        bg-green-100 text-green-800 text-sm
                        invisible opacity-20 -translate-x-3 transition-all
                        group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
                    `}>
                            {text}
                        </div>
                    )}
                </Link>
            )}

        </li>
    );
}

export function NestedSidebarItem({ text, route }) {
    return (
        <li>
            <Link to={route} className={`
                flex items-center py-2 px-6 font-medium rounded-md hover:bg-green-200 text-gray-600
            `}>
                <BiSubdirectoryRight /> {text}
            </Link>
        </li>
    );
}
