// DateFilterButtons.js
import React, { useState } from 'react';
import { DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;
const DateRange = {
    TODAY: { days: 0 },
    YESTERDAY: { days: 1 },
    LAST_7_DAYS: { days: 7 },
    LAST_30_DAYS: { days: 30 },
    LAST_3_MONTHS: { days: 90 },
    LAST_6_MONTHS: { days: 180 },
    LAST_12_MONTHS: { days: 365 },
};

const DateFilterButtons = ({ onDateFilterChange }) => {
    const [selectedRange, setSelectedRange] = useState(null);

    const handleDateChange = (dates, dateStrings) => {
        setSelectedRange(dates ? { startDate: dates[0], endDate: dates[1] } : null);
        // Seçilen tarih aralığını üst bileşene iletiyoruz:
        onDateFilterChange(dates ? { startDate: dates[0], endDate: dates[1] } : null); 
    };

    const handleClick = (range) => {
        setSelectedRange(null); // Özel tarih seçiminde diğer predefined filtreleri kaldırma
        onDateFilterChange(range);
    };
    return (
        <div className="flex gap-1">
            <Space direction="vertical" size={12}>
            <RangePicker onChange={handleDateChange} />
            </Space>
            <div>
                <button className='h-full bg-white transition-all ease-in rounded-l text-sm border border-gray-300 border-r-gray-200 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.TODAY)}>Today</button>
                <button className='h-full bg-white transition-all ease-in text-sm border-r border-t border-b border-gray-300 border-r-gray-200 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.YESTERDAY)}>Yesterday</button>
                <button className='h-full bg-white transition-all ease-in text-sm border-r border-t border-b border-gray-300 border-r-gray-200 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.LAST_7_DAYS)}>7D</button>
                <button className='h-full bg-white transition-all ease-in text-sm border-r border-t border-b border-gray-300 border-r-gray-200 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.LAST_30_DAYS)}>30D</button>
                <button className='h-full bg-white transition-all ease-in text-sm border-r border-t border-b border-gray-300 border-r-gray-200 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.LAST_3_MONTHS)}>3M</button>
                <button className='h-full bg-white transition-all ease-in text-sm border-r border-t border-b border-gray-300 border-r-gray-200 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.LAST_6_MONTHS)}>6M</button>
                <button className='h-full bg-white transition-all ease-in rounded-r text-sm border border-l-0 border-gray-300 text-black py-1 px-3 hover:bg-gray-100' onClick={() => handleClick(DateRange.LAST_12_MONTHS)}>12M</button>
            </div>
        </div>
    );
};

export default DateFilterButtons;
