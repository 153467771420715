"use client"
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { FiPlus } from "react-icons/fi";
import { IoIosSearch } from "react-icons/io";
import { FaBagShopping } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { LuExternalLink } from "react-icons/lu";
import MainLayout from "../../layouts/mainLayout" 

const AppsContainer = () => {
    const [data, setData] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await fetch('https://findbluapp.com/web/apps', {
                method: 'GET',
                headers: {
                    'Authorization': process.env.REACT_APP_APPS_KEY // API anahtarınızı burada belirtin
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // Arama fonksiyonu
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filtrelenmiş uygulama listesini oluştur
    const filteredApps = data && data.filter(app => app.appName.toLowerCase().includes(searchTerm.toLowerCase()));

    // Kopyalama fonksiyonu
    const handleCopyToClipboard = (value) => {
        navigator.clipboard.writeText(value)
            .then(() => {
                console.log('Copied to clipboard:', value);
                // Burada isterseniz kullanıcıya bir geribildirim gösterebilirsiniz
            })
            .catch((error) => {
                console.error('Error copying to clipboard:', error);
            });
    };
    return (
        <MainLayout>
            <div className={styles.appsContainer}>
                <h2>Application List</h2>
                <div className={styles.appsHeading}>
                    <div className={styles.searchDiv}><IoIosSearch /><input type="search" placeholder='Search' onChange={handleSearch} /></div>
                    <Link to={`/apps/createnewapp`} className={styles.newAppBtn}><FiPlus /> New App</Link>
                </div>
                <div className={styles.boxWrapper}>
                    {filteredApps ? (
                        filteredApps.map(app => (
                            <div className={styles.box} key={app.id}>
                                <div className={styles.appHeaderWrapper}>
                                    <div className={styles.appLogoWrapper}>
                                        <img width='100%' src={app.appImgUrl} alt={app.appName} />
                                    </div>
                                    <div className={styles.appUtils}>
                                        <button data-value={app.appMarketId} onClick={() => handleCopyToClipboard(app.appMarketId)}><FaBagShopping /></button>
                                        <button data-value={app.appBundle} onClick={() => handleCopyToClipboard(app.appBundle)}><FaApple /></button>
                                        <button ><LuExternalLink /></button>
                                    </div>
                                </div>
                                <div className={styles.appInfoWrapper} data-bundle={app.appBundle}>
                                    <p className={styles.appName}>{app.appName}</p>
                                    <p className={styles.appEmail}>{app.appEmail}</p>
                                </div>
                                <div className={styles.linkWrapper}>
                                    <Link to={`/apps/${app.id}`} className={styles.linkBtn}>View</Link>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </div>
        </MainLayout>
    );
}

export default AppsContainer;