import React from "react";
import SidebarContainer from "../components/sidebar"; // Header bileşeninin doğru yolunu ayarlayın
import Footer from "../components/footer"; // Footer bileşeninin doğru yolunu ayarlayın
import Header from "../components/header"; // Footer bileşeninin doğru yolunu ayarlayın


const MainLayout = ({ children }) => {
    return (
        <div className="App mainLayout relative">
            <SidebarContainer />
            <main>
                <Header/>
                {children}
                <Footer />
            </main>
        </div>
    );
};

export default MainLayout;