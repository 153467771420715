// index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import './styles/index.css'
import App from './App';
import AppsContainer from './containers/appscontainer';
import ErrorPage from "./error-page";
import LoginContainer from "./containers/logincontainer";
import ProtectedRoutes from './protectedroutes'; // Özel Route bileşenini ekleyin
import AppElement from './containers/appelement';
import Demodevice from './containers/demodevice';
import DrawViewData from './containers/drawviewdatacontainer';
import PaywallActions from './containers/paywallactionscontainer';
import EventsSubscribe from './containers/subscribecontainer';
import EventsPaywallOpen from './containers/paywallopencontainer';
import EventsFirstInstall from './containers/firstinstallcontainer';
import ImageUpload from './containers/imageuploadcontainer';
import ImageUploadCreateNewCategory from './containers/imageupload_new';
import ImageUploadCategoryContainer from './containers/imageupload_category';

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginContainer />,
  },
  {
    path: "/",
    element: <ProtectedRoutes />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <App /> },
      { path: "/apps", element: <AppsContainer />, },
      { path: "/apps/:id", element: <AppElement />, },
      { path: "/apps/:id/demodevice", element: <Demodevice /> },
      { path: "/apps/:id/paywallactions", element: <PaywallActions /> },
      { path: "/apps/:id/events/subscribe", element: <EventsSubscribe /> },
      { path: "/apps/:id/events/paywallopen", element: <EventsPaywallOpen /> },
      { path: "/apps/:id/events/firstinstall", element: <EventsFirstInstall /> },
      { path: "/apps/:id/viewdata", element: <DrawViewData /> },
      { path: "/apps/:id/imageupload", element: <ImageUpload />, },
      { path: "/apps/:id/imageupload/new", element: <ImageUploadCreateNewCategory /> },
      { path: "/apps/:id/imageupload/:categoryname", element: <ImageUploadCategoryContainer /> },
    ]
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
