import React from "react";
import MainLayout from "./layouts/mainLayout"; // Layout dosyasını import edin
import LogoutBtn from "./logout";
import { Helmet } from 'react-helmet';
import favicon from './logo.jpg'; // favicon dosyanızın yolunu doğru şekilde belirtin
function App() {
  return (
    <MainLayout>
      <Helmet>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
        <link rel="icon" type="image/png" href={favicon} sizes="64x64" />
        <link rel="icon" type="image/png" href={favicon} sizes="128x128" />
        <link rel="shortcut icon" href={favicon} type="image/png" />
        <link rel="apple-touch-icon" sizes="180x180" href={favicon} />
        <meta name="msapplication-TileImage" content={favicon} />
        <title>Home | Eventboi</title>
      </Helmet>
      <h1>Home page</h1>
      <LogoutBtn />
    </MainLayout>
  );
}

export default App;
