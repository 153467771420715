import React from 'react'
import styles from './styles.module.css';
import { Breadcrumb } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { AiOutlineSearch } from "react-icons/ai";
import { PiCommandBold } from "react-icons/pi";

export default function Header() {
    const location = useLocation();
    const { pathname } = location;
    const pathnames = pathname.split('/').filter(item => item);
    return (
        <div className={styles.header}>
            <div>
                <Breadcrumb>
                    {pathnames.length > 0 ? (
                        <Breadcrumb.Item>
                            <Link to='/'>Home</Link>
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                    )}
                    {pathnames.map((name, index) => {
                        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
                        const isLast = index === pathnames.length - 1;
                        return (
                            <Breadcrumb.Item key={index}>
                                {isLast ? (
                                    name
                                ) : (
                                    <Link to={routeTo}>{name}</Link>
                                )}
                            </Breadcrumb.Item>
                        );
                    })}
                </Breadcrumb>
            </div>
            <div className={styles.searchWrapper}>
                <span><AiOutlineSearch /></span>
                <span>Quick Search...</span>
                <span><PiCommandBold />+K</span>
            </div>
        </div>
    )
}
