import React from "react";

import styles from "./styles.module.css";

function Footer() {
    const timeYear = new Date().getFullYear(); // get current year
    return (
        <footer className={styles.footer}>
            <div className="left">Copyright © {timeYear} </div>
            <div className="right">support@findbluapp.com</div>
        </footer>
    );
}

export default Footer;