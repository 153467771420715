import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Table } from 'antd';
import MainLayout from '../../layouts/mainLayout';
import DateFilterButtons from '../../components/datefilterbuttons';
import GroupedDataView from '../../components/groupdataview';
const EventsFirstInstall = () => {
    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== ''); // URL'yi '/' karakterlerine göre ayırıp boş olmayanları alıyoruz
    const appid = segments[1];
    const groupedData = {
        action: {},
        placement: {},
        country: {}
    };
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sorter, setSorter] = useState({});
    const [formattedCountries, setFormattedCountries] = useState({});
    const [recordIds] = useState({});

    const handleTableChange = (pagination, filters, sorter) => {
        // Sıralama durumunu güncelle
        setSorter(sorter);
    };


    useEffect(() => {
        const fetchData = async (appId) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://findbluapp.com/web/eventfirstinstall', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'get',
                        appId: appId
                    })
                });
                const responseData = await response.json();
                const newDataWithIds = responseData.data.map((record, index) => {
                    if (!record.id) {
                        const newId = index;
                        record.id = newId;
                        recordIds[newId] = record;
                    }
                    return record;
                });

                setData(newDataWithIds);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData(appid);
    }, [appid]);

    useEffect(() => {
        handleDateFilterChange({ days: 0 });
        const fetchAllCountries = async () => {
            const uniqueCountryCodes = new Set(data.map(item => item.firstInstall.country));

            try {
                const response = await fetch('https://findbluapp.com/web/countries', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        countryCodes: Array.from(uniqueCountryCodes) // Send all codes at once
                    })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                // Assuming responseData is an object where keys are country codes
                setFormattedCountries(responseData.data);
            } catch (error) {
                console.error('Error fetching formatted countries:', error);
            }
        };

        fetchAllCountries();
    }, [data]);


    const [filteredData, setFilteredData] = useState(data);

    const handleDateFilterChange = (range) => {
        console.log(range);
        if (range === null) {
            setFilteredData(data);
            return;
        }
        if (range.days === 0) { 
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Bugünün başladığı zamana ayarla (Saat 00:00:00) 
            const filtered = data.filter((item) => {
                const entryDate = new Date(item.firstInstall.entryDate);
                return entryDate >= today;
            });
            setFilteredData(filtered);
            return; 
        }

        if (range.days || range.months) {
            // Hızlı filtreler (Today, Yesterday, ...) için kod aynı kalır
            const today = new Date();
            let startDate = new Date();

            if (range.days) {
                startDate.setDate(today.getDate() - range.days);
            } else if (range.months) {
                startDate.setMonth(today.getMonth() - range.months);
            }

            const filtered = data.filter((item) => {
                const entryDate = new Date(item.firstInstall.entryDate);
                return entryDate >= startDate;
            });
            setFilteredData(filtered);
        } else {
            // Custom range kullanıldığında:
            const filtered = data.filter((item) => {
                const entryDate = new Date(item.firstInstall.entryDate);
                return (entryDate >= range.startDate && entryDate <= range.endDate);
            });
            setFilteredData(filtered);
        }
    };

    filteredData.forEach((item) => {
        // Country with formatted name (This is the main change)
        const countryCode = item.firstInstall.country;
        const countryName = formattedCountries[countryCode]?.name || countryCode;
        const countryIconUrl = formattedCountries[countryCode]?.iconUrl || ''; // Get iconUrl if available

        if (!groupedData.country[countryName]) {
            groupedData.country[countryName] = {
                name: countryName,
                iconUrl: countryIconUrl,
                count: 0
            };
        }
        groupedData.country[countryName].count++;

    });
    const sortedCountryEntries = Object.entries(groupedData.country).sort(([, a], [, b]) => b.count - a.count);
    groupedData.country = Object.fromEntries(sortedCountryEntries);

    const columns = [
        {
            title: 'Country',
            dataIndex: ['firstInstall', 'country'],
            key: 'country',
            render: (text, record) => {
                const countryCode = record.firstInstall.country;
                const formattedCountry = formattedCountries[countryCode];
                if (formattedCountry) {
                    return (
                        <div className='flex items-center justify-start gap-2'>
                            <img width="20" className='h-4' src={formattedCountry.iconUrl} alt={formattedCountry.name} />
                            <span>{formattedCountry.name}</span>
                        </div>
                    );
                } else {
                    return <div>Loading country data...</div>;
                }
            },
        },

        {
            title: 'Date',
            dataIndex: ['firstInstall', 'entryDate'],
            key: 'entryDate',
            sorter: (a, b) => new Date(a.firstInstall.entryDate) - new Date(b.firstInstall.entryDate), // Tarihleri karşılaştıran sıralama fonksiyonu
        },
    ];

    return (
        <MainLayout>
            <div>
                <DateFilterButtons onDateFilterChange={handleDateFilterChange} />
                <div className=''>
                    <div className='p-2 bg-white rounded-l'>
                        <p>Total PaywallOpen: <b>{filteredData.length}</b></p>
                    </div>
                    <br />
                    <GroupedDataView title="Country" data={groupedData.country} />
                </div>
            </div>
            <div>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{ pageSize: 8 }}
                        loading={isLoading}
                        rowKey={(record) => record.id}
                        onChange={handleTableChange} // Tablo sıralama değişikliklerini yönetmek için
                        sorter={sorter} // Sıralama durumu
                    />
                )}
            </div>
        </MainLayout>
    );
};

export default EventsFirstInstall;
