import React from 'react';
import MainLayout from '../../layouts/mainLayout';
import { Form, Input, Button, message } from 'antd';

export default function ImageUploadCreateNewCategory() {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        console.log('Form Values:', values.categoryName);

        // Form verilerini göndermek için API isteğinizi burada yapın
        try {
            const response = await fetch('https://findbluapp.com/web/imageupload', {
                method: 'POST',
                headers: {
                    'Authorization': process.env.REACT_APP_DRAW_CATEGORIES_KEY
                },
                body: JSON.stringify({
                    action: "insertcategory",
                    categoryName: values.categoryName.toLowerCase()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const fetchedData = await response.json();
            console.log(fetchedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        // Başarılı olursa, örneğin: ,
        message.success('Category created successfully!');
    };

    return (
        <MainLayout>
            <div>
                <h1>Create New Category</h1>
                <Form form={form} onFinish={onFinish}>
                    <Form.Item name="categoryName" label="Category Name" rules={[{ required: true, message: 'Please enter a category name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Create Category
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </MainLayout>
    );
}
