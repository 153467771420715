import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/mainLayout';
import { Table, Button, Modal, message, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styles from './styles.module.css';
import { IoIosSearch } from 'react-icons/io';

const ImageUploadCategoryContainer = () => {
    const [data, setData] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [updatedData, setUpdatedData] = useState(data);

    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== '');
    const catId = segments[3];

    const fetchData = async () => {
        try {
            const response = await fetch('https://findbluapp.com/web/imageupload', {
                method: 'POST',
                headers: {
                    'Authorization': process.env.REACT_APP_DRAW_CATEGORIES_KEY
                },
                body: JSON.stringify({
                    action: "categoriesElements",
                    categoryId: catId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const fetchedData = await response.json();
            fetchedData.sort((a, b) => b.imgId - a.imgId);
            setData(fetchedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [catId]);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imgPath',
            key: 'imgPath',
            render: imgPath => <img src={imgPath} alt="preview" width="50" />
        },
        {
            title: 'Name',
            dataIndex: 'imgName',
            key: 'imgName',
        },
        {
            title: 'Size (MB)',
            dataIndex: 'imgSize',
            key: 'imgSize',
            render: imgSize => (imgSize / 1024 / 1024).toFixed(2),
        },
    ];

    const handleUploadChange = ({ fileList }) => {
        setFileList(fileList.filter(file => !!file.status));
    };

    const handleUpload = async () => {
        if (!fileList.length) {
            message.error('Lütfen dosya seçin!');
            return;
        }

        try {
            console.log(fileList);
            const promises = fileList.map(async file => {
                const formData = new FormData();
                formData.append('imageUpload', file.originFileObj);
                formData.append('categoryId', catId);
                formData.append('action', "insertimage");

                const response = await fetch('https://findbluapp.com/web/imageupload', {
                    method: 'POST',
                    headers: {
                        'Authorization': process.env.REACT_APP_DRAW_CATEGORIES_KEY,
                    },
                    body: formData
                });

                if (!response.ok) {
                    throw new Error(`Görsel yüklemesi başarısız! (${file.name})`); 
                }

                return await response.json();
            });

            const results = await Promise.all(promises);

            // Yükleme sonuçlarını incele ve kullanıcıya geri bildirim ver
            const successfulFiles = results.filter(result => result.status === 'success').map(result => result.uploadedFiles[0]); 
            const failedFiles = results.filter(result => result.status === 'error'); 

            if (successfulFiles.length) {
                message.success(`${successfulFiles.length} görsel başarıyla yüklendi!`); 
            }
            if (failedFiles.length) {
                message.error(`${failedFiles.length} görsel yüklenemedi!`);
            }

            setFileList([]);
            setIsUploadModalVisible(false);
            fetchData();

        } catch (error) { 
            message.error('Görsel yükleme sırasında hata oluştu!');
            console.error('Görsel yükleme hatası:', error);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const newFilteredData = data.filter(img => {
            return img.imgName && img.imgName.toLowerCase().includes(searchTerm.toLowerCase());
        });
        setUpdatedData(newFilteredData);

    }, [searchTerm, data]);

    return (
        <MainLayout>
            <div>
                <div className={styles.appsHeading}>
                    <div>
                        <div className={styles.searchDiv}><IoIosSearch /><input type="search" placeholder='Search' onChange={handleSearch} /></div>
                    </div>
                    <div>
                        <Button onClick={() => setIsUploadModalVisible(true)} type="primary">
                            <UploadOutlined />  Yeni Görsel Yükle
                        </Button>
                        <Modal
                            visible={isUploadModalVisible}
                            title="Görsel Yükle"
                            onCancel={() => setIsUploadModalVisible(false)}
                            onOk={handleUpload}
                        >
                            <Upload
                                fileList={fileList}
                                onChange={handleUploadChange}
                                multiple
                                directory
                            >
                                <Button icon={<UploadOutlined />}>Dosya Seç</Button>
                            </Upload>
                        </Modal>
                    </div>
                </div>
                <Table dataSource={updatedData} columns={columns} rowKey="imgId" />
            </div>
        </MainLayout>
    );
};

export default ImageUploadCategoryContainer;
