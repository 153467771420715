import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/mainLayout';
import DateFilterButtons from '../../components/datefilterbuttons';
import { Table } from 'antd';
import styles from './styles.module.css';

const ElementApp = () => {
    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== '');
    const appid = segments[1];

    const [data, setData] = useState({
        subscribeData: [],
        firstInstall: []
    });
    const [recordIds] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(data);
    const [productCodeCounts, setProductCodeCounts] = useState({});

    // Fetch Data
    useEffect(() => {
        const fetchSubscribeData = async (appId) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://findbluapp.com/web/eventsubscribe', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'get',
                        appId: appId
                    })
                });
                const result = await response.json();
                setData(prevData => ({
                    ...prevData,
                    subscribeData: result.data.successfulAttemps.map(record => ({ ...record, id: recordIds[record.id] })),
                }));
            } catch (error) {
                console.error('Error fetching subscribe data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchFirstInstallData = async (appId) => {
            try {
                setIsLoading(true);
                const response = await fetch('https://findbluapp.com/web/eventfirstinstall', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        action: 'get',
                        appId: appId
                    })
                });
                const result = await response.json();
                setData(prevData => ({
                    ...prevData,
                    firstInstall: result.data.map(record => ({ ...record, id: recordIds[record.id] })),
                }));
            } catch (error) {
                console.error('Error fetching first install data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubscribeData(appid);
        fetchFirstInstallData(appid);
    }, [appid]);

    // Filter Data for Today (range.days === 0) after Fetch
    useEffect(() => {
        const filterTodayData = () => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const filtered = {
                subscribeData: data.subscribeData.filter((item) => {
                    const entryDate = new Date(item.subscribe.entryDate);
                    return entryDate >= today;
                }),
                firstInstall: data.firstInstall.filter((item) => {
                    const entryDate = new Date(item.firstInstall.entryDate);
                    return entryDate >= today;
                }),
            };
            setFilteredData(filtered);
        };

        if (data.subscribeData.length > 0 || data.firstInstall.length > 0) {
            filterTodayData();
        }
    }, [data]);

    useEffect(() => {
        calculateProductCodeCounts(filteredData);
    }, [filteredData]);

    const handleDateFilterChange = (range) => {
        if (range === null) {
            setFilteredData(data);
            return;
        }
        if (range.days === 0) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const filtered = {
                subscribeData: data.subscribeData.filter((item) => {
                    const entryDate = new Date(item.subscribe.entryDate);
                    return entryDate >= today;
                }),
                firstInstall: data.firstInstall.filter((item) => {
                    const entryDate = new Date(item.firstInstall.entryDate);
                    return entryDate >= today;
                }),
            };
            setFilteredData(filtered);
            return;
        }
        if (range.days || range.months) {
            const today = new Date();
            let startDate = new Date();
            if (range.days) {
                startDate.setDate(today.getDate() - range.days);
            } else if (range.months) {
                startDate.setMonth(today.getMonth() - range.months);
            }
            const filtered = {
                subscribeData: data.subscribeData.filter(item => {
                    let originalEntryDate = item.subscribe.entryDate;
                    if (originalEntryDate.endsWith(":60")) {
                        item.subscribe.entryDate = originalEntryDate.slice(0, -2) + "59";
                    }
                    let entryDate = new Date(item.subscribe.entryDate);
                    if (isNaN(entryDate.getTime())) {
                        return false;
                    }
                    return entryDate >= startDate;
                }),
                firstInstall: data.firstInstall.filter(item => {
                    let originalEntryDate = item.firstInstall.entryDate;
                    if (originalEntryDate.endsWith(":60")) {
                        item.firstInstall.entryDate = originalEntryDate.slice(0, -2) + "59";
                    }
                    let entryDate = new Date(item.firstInstall.entryDate);
                    if (isNaN(entryDate.getTime())) {
                        return false;
                    }
                    return entryDate >= startDate;
                })
            };
            setFilteredData(filtered);
        } else {
            const today = new Date();
            const filtered = {
                subscribeData: data.subscribeData.filter(item => {
                    const entryDate = new Date(item.subscribe.entryDate);
                    return (entryDate >= range.startDate && entryDate <= range.endDate && entryDate <= today);
                }),
                firstInstall: data.firstInstall.filter(item => {
                    const entryDate = new Date(item.firstInstall.entryDate);
                    return (entryDate >= range.startDate && entryDate <= range.endDate && entryDate <= today);
                })
            };
            setFilteredData(filtered);
        }
    };

    function formatNumber(number) {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return number;
        }
    }

    function calculateProductCodeCounts(data) {
        const productCodeCounts = {};

        const productCode = data.subscribeData.map(attempt => attempt.subscribe.productCode);

        productCode.forEach(code => {
            if (productCodeCounts[code]) {
                productCodeCounts[code]++;
            } else {
                productCodeCounts[code] = 1;
            }
        });

        setProductCodeCounts(productCodeCounts);
    }

    const productColumns = [
        {
            title: 'Product Code',
            dataIndex: 'productCode',
            key: 'productCode',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1', marginRight: '8px' }}>
                        <div
                            style={{
                                backgroundColor: '#1890ff',
                                width: `${(record.count / data.subscribeData.length) * 100}%`,
                                height: '8px',
                            }}
                        />
                    </div>
                    <span>{((record.count / data.subscribeData.length) * 100).toFixed(2)}%</span>
                </div>
            ),
        },
    ];

    const productData = Object.entries(productCodeCounts).map(([productCode, count]) => ({
        productCode,
        count,
    }));

    return (
        <MainLayout>
            <div>
                <DateFilterButtons onDateFilterChange={handleDateFilterChange} />
                <div className={styles.boxWrapper}>
                    <div className={styles.dataBox}>
                        <p className={styles.dataTitle}>Subscribe</p>
                        <p className={styles.dataCount}>
                            {isLoading ? 'Loading...' : formatNumber(filteredData ? filteredData.subscribeData.length : data.subscribeData.length)}
                        </p>
                    </div>
                    <div className={styles.dataBox}>
                        <p className={styles.dataTitle}>First Install</p>
                        <p className={styles.dataCount}>
                            {isLoading ? 'Loading...' : formatNumber(filteredData ? filteredData.firstInstall.length : data.firstInstall.length)}
                        </p>
                    </div>
                </div>
                <div className={styles.productSummary}>
                    <h2>Product Code Summary</h2>
                    <Table columns={productColumns} dataSource={productData} rowKey={(record) => record.productCode} pagination={false} />
                </div>
            </div>
        </MainLayout>
    );
}

export default ElementApp;
