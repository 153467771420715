import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            // Kullanıcıdan onay alın
            if (window.confirm('Çıkış yapmak istediğinizden emin misiniz?')) {
                // Redux state'ini güncelleyin
                dispatch({ type: 'LOGOUT' });
                
                // Sunucuya çıkış isteği gönderin
                const response = await fetch('https://findbluapp.com/web/logout', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'tXWeqKgSslLkRwW4V5jD'
                    }
                });
                
                // Sunucudan başarılı bir cevap alındığında kullanıcıyı ana sayfaya yönlendirin
                if (response.ok) {
                    navigate('/login', { replace: true });
                } else {
                    console.error('Logout failed');
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <button className='bg-rose-500 btn' onClick={handleLogout}>Çıkış Yap</button>
    );
};

export default Logout;