const GroupedDataView = ({ title, data }) => {
    return (
        <div className="mb-4"> 
            <h3 className="text-lg font-medium">{title}</h3>
            <div className="flex flex-wrap gap-2"> 
                {Object.entries(data).map(([key, countryData]) => ( 
                    <div key={key} className="p-2 bg-white rounded"> 
                        {title === 'Country' ? (
                            <div className="flex items-center">
                                <img src={countryData.iconUrl} alt={countryData.name} width="20" className="h-4" />
                                <p className="ml-2"><b>{countryData.name}: </b>{countryData.count}</p>
                            </div>
                        ) : (
                            <p><b>{key}: </b>{countryData}</p> // Default rendering for other titles
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};


export default GroupedDataView;