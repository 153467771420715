import React, { useState, useEffect } from 'react';
import MainLayout from '../../layouts/mainLayout';
import { Table } from 'antd';
const DrawViewDataContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await fetch('https://findbluapp.com/web/drawviewdata', {
                method: 'POST',
                headers: {
                    'Authorization': process.env.REACT_APP_DRAW_DATA // API anahtarınızı burada belirtin
                },
                body: JSON.stringify({
                    action: 'get',
                })
            });
            const responseData = await response.json();
            setData(responseData);
            console.log(responseData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            setIsLoading(true);
            await fetchData();
        };
        fetchDataAsync();
    }, []);

    const sortByImageCount = (a, b) => {
        return b.imageCount - a.imageCount; // Büyükten küçüğe sıralama
    };

    const sortCategoryCount = (a, b) => {
        return b.categoryCount - a.categoryCount; // Büyükten küçüğe sıralama
    };

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imgPath',
            key: 'imgPath',
            render: (imgPath) => <img width="70" src={imgPath} alt="" />,
        },
        {
            title: 'Image Count',
            dataIndex: 'imageCount',
            key: 'imageCount',
        },
        {
            title: 'Percent',
            dataIndex: 'imagePercent',
            key: 'imagePercent',
        },
    ];

    return (
        <MainLayout>
            <div>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <div className='grid gap-4'>
                        <section className='categories flex flex-wrap gap-2'>
                            {data && data.categoryCounts && data.categoryCounts
                                .sort(sortCategoryCount) // categoryCounts dizisini sırala
                                .map((el, index) => (
                                    <div className="box bg-white rounded p-2" key={index}>
                                        <h4>{el.categoryName}</h4>
                                        <p><b>Category Count: </b>{el.categoryCount}</p>
                                        <p><b>Category Percent: </b>{el.categoryPercent}</p>
                                    </div>
                                ))}
                        </section>
                        <Table
                            columns={columns}
                            dataSource={
                                data && data.imageCounts && data.imageCounts.sort(sortByImageCount)
                            }
                            rowKey="imgPath"
                        />
                    </div>
                )}
            </div>
        </MainLayout>
    );
};

export default DrawViewDataContainer;
