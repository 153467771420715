import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Button, Input } from 'antd'; // Ant Design bileşenlerini içe aktarıyoruz

import MainLayout from '../../layouts/mainLayout';

const PaywallactionsContainer = () => {
    const location = useLocation();
    const currentPathName = location.pathname;
    const segments = currentPathName.split('/').filter(segment => segment !== ''); // URL'yi '/' karakterlerine göre ayırıp boş olmayanları alıyoruz
    const appid = segments[1];

    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({
        actionKey: '',
        placementId: '',
        onCloseOpen: '0',
        onCloseId: '0',
        showPaywall: '1',
        isAbTest: '0',
        onCloseIsAbTest: '0',
        appId: appid
    });
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://findbluapp.com/web/paywallactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'get',
                    appId: appid
                })
            });
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [appid]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://findbluapp.com/web/paywallactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'insert',
                    formData: formData
                })
            });
            console.log(response);
            fetchData();

            setFormData({
                actionKey: '',
                placementId: '',
                onCloseOpen: '0',
                onCloseId: '0',
                showPaywall: '1',
                isAbTest: '0',
                onCloseIsAbTest: '0',
                appId: appid
            });
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };

    const handleDelete = async (rowId) => {
        try {
            const response = await fetch('https://findbluapp.com/web/paywallactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'delete',
                    rowId: rowId
                }),
            });

            if (response.ok) {
                fetchData();
            } else {
                console.error('Response is not OK');
            }
        } catch (error) {
            console.error('Error deleting device:', error);
        }
    };

    const handleEdit = (index) => {
        const newData = [...data];
        newData[index].editMode = true; // Düzenleme modunu etkinleştir
        setData(newData);
    };

    const handleSave = async (index) => {
        const updatedData = data[index];

        try {
            const response = await fetch('https://findbluapp.com/web/paywallactions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'update',
                    updatedData: updatedData
                }),
            });

            if (response.ok) {
                fetchData();
            } else {
                console.error('Response is not OK');
            }

            const newData = [...data];
            newData[index].editMode = false;
            setData(newData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const columns = [ // Sütunların tanımları
        {
            title: 'Name',
            dataIndex: 'action_key',
            key: 'action_key',
        },
        {
            title: 'Placement',
            dataIndex: 'placement_id',
            key: 'placement_id',
        },
        {
            title: 'On Close',
            dataIndex: 'on_close_id',
            key: 'on_close_id',
            render: (text) => (text === 0 ? 'none' : text), // Render fonksiyonu örneği
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record, index) => ( // Render fonksiyonu örneği
                <div className='flex gap-2'>
                    {record.editMode ? (
                        <Button onClick={() => handleSave(index)}>Save</Button>
                    ) : (
                        <Button onClick={() => handleEdit(index)}>Edit</Button>
                    )}
                    <Button onClick={() => handleDelete(record.id)}>Delete</Button>
                </div>
            ),
        },
    ];

    return (
        <MainLayout>
            <div>
                <form onSubmit={handleSubmit} className='flex gap-4 mb-4'>
                    <Input
                        type="text"
                        placeholder='Action Name'
                        name="actionKey"
                        value={formData.actionKey}
                        onChange={(e) => setFormData({ ...formData, actionKey: e.target.value })}
                        className='p-2 text-black rounded'
                    />
                    <Input
                        type="text"
                        placeholder='Placement Id'
                        name="placementId"
                        value={formData.placementId}
                        onChange={(e) => setFormData({ ...formData, placementId: e.target.value })}
                        className='p-2 text-black rounded'
                    />
                    <select name="onCloseOpen" value={formData.onCloseOpen}
                        onChange={(value) => setFormData({ ...formData, onCloseOpen: value })}
                        className='p-2 text-black rounded'
                    >
                        <option value="0">None</option>
                        <option value="ads">Ads</option>
                        <option value="placement">Placement</option>
                    </select>
                    <select name="onCloseId" defaultValue={formData.onCloseId}
                        onChange={(value) => setFormData({ ...formData, onCloseId: value })}
                        className='p-2 text-black rounded'
                    >
                        <option value="0">None</option>
                        <option value="ads2">ads2</option>
                        <option value="pushAction-allUsers">pushAction-allUsers</option>
                    </select>
                    <Button type="primary" htmlType="submit">Add</Button>
                </form>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <Table dataSource={data} columns={columns} />
                )}
            </div>
        </MainLayout>
    );
};

export default PaywallactionsContainer;
